import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import toast from "react-hot-toast";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => {
        const { data } = response;
        if (data) {
          if (data.success === false) {
            const { notifications } = data;
            if (Array.isArray(notifications) && notifications?.length > 0) {
              try {
                const messages = notifications.map((item) => {
                  return `${item.property}: ${item.message}`;
                });

                const errorMessage = messages.join("\n");

                toast.error(errorMessage, {
                  position: "top-center",
                  style: {
                    borderRadius: "10px",
                    background: "#ea5455",
                    color: "#fff",
                  },
                });
              } catch (e) { }
            }
          }
        }

        return response;
      },
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 500) {
          toast.error(
            "Um erro inesperado aconteceu, tente novamente em alguns segundos.",
            {
              position: "top-center",
              style: {
                borderRadius: "10px",
                background: "#ea5455",
                color: "#fff",
              },
            }
          );
        }
        // ** if (status === 401) {
        if (response && response.status === 401 && !config?.url?.includes("login")) {
          window.location.href = '/login'
          //   if (!this.isAlreadyFetchingAccessToken) {
          //     this.isAlreadyFetchingAccessToken = true;
          //     this.refreshToken().then((r) => {
          //       this.isAlreadyFetchingAccessToken = false;

          //       // ** Update accessToken in localStorage
          //       this.setToken(r.data.accessToken);
          //       this.setRefreshToken(r.data.refreshToken);

          //       this.onAccessTokenFetched(r.data.accessToken);
          //     });
          //   }
          //   const retryOriginalRequest = new Promise((resolve) => {
          //     this.addSubscriber((accessToken) => {
          //       // ** Make sure to assign accessToken according to your response.
          //       // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          //       // ** Change Authorization header
          //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
          //       resolve(this.axios(originalRequest));
          //     });
          //   });
          //   return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => {
      callback(accessToken)
    });
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    const token = localStorage.getItem(this.jwtConfig.storageTokenKeyName);
    return token;
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(args) {
    return axios.post(this.jwtConfig.loginEndpoint, {
      ...args,
      token: this.jwtConfig.loginToken,
    });
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  post(url, data) {
    return axios.post(this.jwtConfig.apiEndpoint + url, { ...data });
  }

  put(url, data) {
    return axios.put(this.jwtConfig.apiEndpoint + url, { ...data });
  }

  delete(url) {
    return axios.delete(this.jwtConfig.apiEndpoint + url)
  }

  get(url, args) {
    return axios.get(this.jwtConfig.apiEndpoint + url, args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
